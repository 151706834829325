

.not-found-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    width: 100%;

}

.inner-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}