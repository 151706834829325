
.header-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-container{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.text-field{
    width: 70%;
    margin: 10px;
}

.download-button{
    margin: 10px;
}
.download-container{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.download-link-container{
    color:white;
    height: 20px;
    margin-top: 30px;
}
.download-link{

}