.container{
    margin: 30px;
}
.cardContainer{
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.circular-progress{
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
}

