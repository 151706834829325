
.footer-container{
    width: 100%;
    background-color: #1976d2;
    color: white;
    display: flex;
    margin: auto;

}

.footer-links{
    color: white;
    text-decoration: none;
}

#Grid{
    display: flex;
    justify-content: center;
    align-items: center;
}